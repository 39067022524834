import React from "react";
import IFrame from "../02_molecules/IFrame";

const AnalyticsPage = () => {
  return (
    <React.Fragment>
      <div style={{ width: "100%", height: "100%" }}>
        <IFrame
          src={"https://analytics.diefamilienzeitung.de"}
          srcDoc={null}
          title="Analytics"
        ></IFrame>
      </div>
    </React.Fragment>
  );
};

export default AnalyticsPage;
