import React from "react";
import { Route } from "react-router-dom";
import CronJobList from "../../components/03_organisms/CronJobs/CronJobList";

import AnalyticsPage from "../../components/05_pages/AnalyticsPage";
import QueueDashboardPage from "../../components/05_pages/QueueDashboardPage";
import ApiDocsPage from "../../components/05_pages/ApiDocsPage";
import HostingPage from "../../components/05_pages/HostingPage";
import BlogPage from "../../components/05_pages/BlogPage";

const customRoutes = [
  <Route exact path="/jobs" component={CronJobList} />,
  <Route exact path="/queues" component={QueueDashboardPage} />,
  <Route exact path="/analytics" component={AnalyticsPage} />,
  <Route exact path="/apiDocs" component={ApiDocsPage} />,
  <Route exact path="/hosting" component={HostingPage} />,
  <Route exact path="/blog" component={BlogPage} />,
];

export default customRoutes;
