import React from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  TextField,
  Filter,
  TextInput,
} from "react-admin";

const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by Title" source="title" alwaysOn />
    <TextInput label="Search by Content" source="description" alwaysOn />
    <TextInput label="Search by Uuid" source="uuid" />
    <TextInput
      label="Search by FreelyAssignedAuthor"
      source="freelyAssignedAuthor"
    />
    <TextInput label="Search by FreelyAssignedLocation" source="locationName" />
    <TextInput
      label="Search by FreelyAssignedDate"
      source="freelyAssignedDate"
    />
  </Filter>
);

export const ArticleList = (props) => (
  <List {...props} filters={<SearchFilter />}>
    <Datagrid rowClick="edit">
      <BooleanField source="isDeleted" />
      <BooleanField source="isDraft" />
      <TextField source="id" />
      <TextField source="uuid" />
      <TextField source="title" />
      <TextField source="description" />
      <TextField source="freelyAssignedAuthor" />
      <TextField source="freelyAssignedDate" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <TextField source="locationName" />
    </Datagrid>
  </List>
);
