import React from "react";
import { Datagrid, DateField, List, TextField } from "react-admin";

export const ContactRequestList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="uuid" />
      <TextField source="requesterName" />
      <TextField source="requesterEmail" />
      <TextField source="subject" />
      <TextField source="message" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
