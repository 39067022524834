import React from "react";
import {
  Datagrid,
  DateField,
  Filter,
  List,
  TextField,
  TextInput,
  ReferenceField,
} from "react-admin";

const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Search by Family Name"
      source="isForFamily.name"
      alwaysOn
    />
  </Filter>
);

export const InvitationList = (props) => (
  <List {...props} filters={<SearchFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="uuid" />
      <ReferenceField
        label="Belongs To Family"
        source="isForFamily.id"
        reference="families"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="invitationCode" />
      <TextField source="invitationType" />

      <TextField source="receiverEmailAdress" />
      <TextField source="deliveryStatus" />
      <TextField source="lastSuccesfulDeliveryAt" />
      <TextField source="subject" />
      <TextField source="personalSalutation" />
      <TextField source="personalMessage" />
      <TextField source="personalSignature" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
