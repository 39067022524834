import React from "react";
import Link from "@material-ui/core/Link";

const BlogPage = () => {
  return (
    <div>
      <h3>Blogging</h3>
      <p>
        <span>DieFamilienzeitung makes use of the headless-CMS </span>
        <Link href={"https://prismic.io"}> prismic.io</Link>
        <span>
          {" "}
          to allow content creation and serving it to the webapp (and mobile
          apps). Visit{" "}
        </span>
        <Link href="https://diefamilienzeitung-contenthub.prismic.io/documents/">
          https://diefamilienzeitung-contenthub.prismic.io/documents/
        </Link>{" "}
        to access the CMS interface.
      </p>
    </div>
  );
};

export default BlogPage;
