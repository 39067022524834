// in src/comments/ApproveButton.js
import { Button } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { fetchEnd, fetchStart, useMutation, useNotify } from "react-admin";
import { useDispatch } from "react-redux";
import { httpClient } from "../../App";
import { API_CONFIG } from "../../common/config/api.config";

/* const useStyles = makeStyles((theme) => {
  return {
    successLabel: {
      color: theme.palette.success.main, // 🔴
    },

    dangerLabel: {
      color: theme.palette.warning.main, // 🔴
    },
  };
}); */

const DownloadNewspaperButton = (props: any) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const { record } = props;

  const [mutate] = useMutation();

  const sendRequest = useCallback(() => {
    const updateNewspaperWithRefreshedDownloadLink = (downloadLink: string) =>
      mutate({
        type: "update",
        resource: "newspapers",
        payload: {
          id: record.id,
          data: { ...record, downloadLink: downloadLink },
        },
      }); 

    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator

    httpClient(
      API_CONFIG.NEWSPAPER_SERVICE.USER_ENDPOINTS.GET_NEWSPAPER_PDF_DOWNLOAD_LINK_URL(
        record.belongsTo.uuid,
        record.uuid
      ),
      {
        method: "GET",
      }
    )
      .then((result) => {
        if (result.status === 200) {
          notify(`Downloadlink refreshed: \n ${result.body}`);
          updateNewspaperWithRefreshedDownloadLink(result.body);
        }
      })
      .catch((e) => {
        notify(
          "Could not fetch an up to date download link. Are you sure the newspaper has already been generated?",
          "warning"
        );
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
      });
  }, [dispatch, mutate, notify, record]);

  //const classes = useStyles();

  return (
    <>
      {record?.printableRepresentationUrl ? (
        <Button
          color="secondary"
          onClick={sendRequest}
          disabled={loading }
          variant={"outlined"}
        >
          {record?.downloadLink
            ? "Refresh Download Link"
            : "Generate Download Link"}
        </Button>
      ) : (

        <Button 
        color="secondary"
        onClick={sendRequest}
        disabled={true }
        variant={"outlined"}
      >
        Not yet downloadable, Generate the newspaper first.
        </Button>
      )}
    </>
  );
};

export default DownloadNewspaperButton;
