import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import React from "react";
import { ImageField } from "react-admin";

const OnlineBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "$ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  })
)(Badge);

const OfflineBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.main,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    },
  })
)(Badge);

const ConditionalProfileImageField = ({ record, ...rest }) => {
  return record && record.hasProfileImage ? (
    <ImageField source="profileImage.url" record={record} {...rest} />
  ) : record.isOnline ? (
    <OnlineBadge
      overlap="circle"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      variant="dot"
    >
      <Avatar>{record.userName ? record.userName.substr(0, 1) : null}</Avatar>
    </OnlineBadge>
  ) : (
    <OfflineBadge
      overlap="circle"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      variant="dot"
    >
      <Avatar>{record.userName ? record.userName.substr(0, 1) : null}</Avatar>
    </OfflineBadge>
  );
};

export default ConditionalProfileImageField;
