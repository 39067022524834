import React from "react";
import Link from "@material-ui/core/Link";

const HostingPage = () => {
  return (
    <div>
      <h3>Hosting</h3>
      <p>
        <span>
          The systems of dieFamilienzeitung, including webservers, databases,
          object-storage and more are hosted over at{" "}
        </span>
        <Link href="https://digitalocean.com">digitalocean.com .</Link>

        <span>
          <br /> Please note that the provider does not allow embedding the
          entire administration UI into other webpages, thus it cannot be
          included in this panel.
        </span>
      </p>
    </div>
  );
};

export default HostingPage;
