// packagrs to configure the axios http client
import axios from 'axios';
import { call, cancelled } from 'redux-saga/effects';

export const apiClient = axios;

export function request(config = {}) {
  return call(function*() {
    const source = axios.CancelToken.source();
    try {
      return yield call(axios.request, {
        cancelToken: source.token,
        ...config,
      });
    } finally {
      if (yield cancelled()) {
        source.cancel();
      }
    }
  });
}

// and then defining verbs

export function getRequest(url: string, config = {}) {
  return request({ method: 'get', url, ...config });
}

export function postRequest(url: string, data: object, config = {}) {
  return request({ method: 'post', url, data, ...config });
}

export function putRequest(url: string, data: object, config = {}) {
  return request({ method: 'put', url, data, ...config });
}

export function deleteRequest(url: string, data: object, config = {}) {
  return request({ method: 'delete', url, data, ...config });
}
