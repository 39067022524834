import React from "react";
import {
  DateInput,
  DateTimeInput,
  Edit,
  FormTab,
  NumberInput,
  TabbedForm,
  TextInput,
} from "react-admin";
import CreateNewReleaseCycleButton from "../../02_molecules/CreateNewReleaseCycleButton/CreateNewReleaseCycleButton";

export const FamilyEdit = (props) => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="Data">
        <TextInput source="id" disabled />
        <TextInput source="uuid" disabled />
        <TextInput source="name" />
        <NumberInput source="newsPaperReleaseCycleInWeeks" />

        <DateTimeInput source="newspaperReleaseCycleStartDate" />
        <DateTimeInput source="nextReleaseDate" />
        {/*  <ReferenceArrayInput source="memberIds" reference="customers">
        <SelectArrayInput optionText="userName" />
      </ReferenceArrayInput>

      <ArrayInput source="members">
        <SimpleFormIterator>
          <ReferenceInput label="Members" source="id" reference="customers">
            <SelectInput optionText="userName" />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput> */}
        <DateInput source="createdAt" disabled />
        <DateInput source="updatedAt" disabled />
      </FormTab>

      <FormTab label="Functions">
        <p>
          "Create New Release Cycle will progress a families 'nextReleaseDate'
          and create a new newspaper for that family. The currently active
          newspaper will be marked as the "last published" edition.
        </p>
        <CreateNewReleaseCycleButton></CreateNewReleaseCycleButton>
      </FormTab>
    </TabbedForm>
  </Edit>
);
