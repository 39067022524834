import { AxiosResponse } from 'axios';
import { apiClient, API_CONFIG, ApiUtilities } from '../api';
import { RegisterAccountDto, UpdateCustomerPasswordWithRecoveryTokenDto } from '../auth/types';
const jwt_decode = require("jwt-decode");

export interface LoginStatus {
  accessToken?: string;
  expiresIn?: string;
}

export interface JwtPayload {
  firstname?: string;
  username: string;
  customerUid: string;
  jit: string;
  exp?: number;
  iat?: number;
  iss?: string;
  sub: string;
  roles?: string[];
}

class AccountService {


  async isTokenExpired(tokenExpiry: number | null): Promise<boolean> {
    const now = Date.now().valueOf() / 1000;
    const tokenNotExpired = typeof tokenExpiry !== 'undefined' && now > tokenExpiry; 
    return tokenNotExpired;
  };


  async isAuthenticated(): Promise<boolean> {
    try {
      const accessToken = await this.getAccessToken();

      if (accessToken) {
        const decodedAccessToken = await this.decodeToken(accessToken);

       
        if (decodedAccessToken) {
          const tokenIsNotExpired = await this.isTokenExpired(decodedAccessToken.exp);
          if(!tokenIsNotExpired){
            return Promise.resolve(true);
          }
        } else {
          return Promise.resolve(false);
        }
      }
      return Promise.resolve(false);


    } catch (error) {
      return Promise.resolve(false);
    }
  }

  async getAccessToken() {
    return localStorage.getItem('token');
  }

  async decodeToken(token: string): Promise<JwtPayload> {
    return jwt_decode(token);
  }

  /**
   * Logs an account into the system using a pair of credentials
   * @param {string} username - A unique identifier for the user
   * @param {string} password - A secret that only the user knows
   * @returns {Promise<AxiosResponse<LoginStatus | undefined>>}
   */
  async login(
    username: string,
    password: string,
  ): Promise<AxiosResponse<LoginStatus | undefined>> {
    try {
      const loginAccountUri = API_CONFIG.ACCOUNT_SERVICE.LOGIN_ACCOUNT_URI();

      const loginRequestBody = {
        username: username,
        password: password,
      };

      return apiClient.post(loginAccountUri, loginRequestBody);
    } catch (error) {
      return ApiUtilities.handleApiError(error);
    }
  }

  /**
   * Logs out an account
   * @param accessToken
   * @returns
   */
  async logout(accessToken: string): Promise<AxiosResponse<any>> {
    const logoutAccountUri: string = API_CONFIG.ACCOUNT_SERVICE.LOGOUT_ACCOUNT_URI();
    return apiClient.post(
      logoutAccountUri,
      {},
      { headers: { Authorization: `Bearer ${accessToken}` } },
    );

    
  }

  /**
   * Creates a new account-entity.
   * @param username - the username of the account that shall be created
   * @param password - the password of the account that shall be created
   */
  async postAccount(
    payload: RegisterAccountDto,
  ): Promise<AxiosResponse<any | undefined>> {
    try {
      const postAccountUri = API_CONFIG.ACCOUNT_SERVICE.POST_NEW_ACCOUNT_URI();

      const postAccountResponse = apiClient.post(postAccountUri, {
        firstName: payload.firstName,
        username: payload.username,
        password: payload.password,
        consentToDataProcessingAgreementGiven:
          payload.consentToDataProcessingAgreementGiven,
        dpaConsentTimestamp: new Date().toString(),
        dpaConsentIsForVersionOfDpa: payload.dpaConsentIsForVersionOfDpa,
        consentToTermsOfServiceGiven: payload.consentToTermsOfServiceGiven,
        tosConsentTimestamp: new Date().toString(),
        tosConsentIsForVersionOfToS: payload.tosConsentIsForVersionOfToS,
      });

      return postAccountResponse;
    } catch (error) {
      return undefined;
    }
  }

  /**
   * Issues a request to initiate the forgot-password flow
   * @param oldPassword - the users current password
   * @param newPassword - the users new password
   * @param customerId - the users customer id <okta user id>
   */
  async initiateForgotPasswordFlow(
    primaryUserEmail: string,
  ): Promise<AxiosResponse<any>> {
    const updatePasswordUri = API_CONFIG.ACCOUNT_SERVICE.FORGOT_PASSWORD_URI();

    const updatePasswordResponse = apiClient.post(updatePasswordUri, {
      primaryUserEmail,
    });

    return updatePasswordResponse;
  }

  /**
   * Issues a request to reset a users password with a one-time-use recovery token
   * that was delivered to the user by some out-of-band mechanism like email, sms or call.
   */
  async resetPasswordWithRecoveryToken(
    requestBody: UpdateCustomerPasswordWithRecoveryTokenDto,
  ): Promise<AxiosResponse<any>> {
    const resetPasswordWithRecoveryTokenUri = API_CONFIG.ACCOUNT_SERVICE.RESET_PASSWORD_URI();

    const updatePasswordResponse = apiClient.post(
      resetPasswordWithRecoveryTokenUri,
      requestBody,
    );

    return updatePasswordResponse;
  }

  /**
   * Isses a HTTP-request that updates a user's primary email adress using a previously
   * generated verification token that is delivered to the user out of band (via email in our case).
   * @param {string} newPrimaryEmail - The email-adress that shall be set as the new priamry / login email for the user
   * @param {string} verificationToken - A verification token signed by the backend.
   */
  async updateUserPrimaryEmail(verificationToken: string) {
    const updatePrimaryEmailUri = API_CONFIG.ACCOUNT_SERVICE.UPDATE_PRIMARY_EMAIL_URI();

    const updatePrimaryEmailResponse = apiClient.put(updatePrimaryEmailUri, {
      verificationToken: verificationToken,
    });

    return updatePrimaryEmailResponse;
  }

  /**
   * Issues a request to update a users password given that his old password is known.
   * @param oldPassword - the users current password
   * @param newPassword - the users new password
   * @param customerId - the users customer id <okta user id>
   */
  async updateUserPassword(
    accountUid: string,
    oldPassword: string,
    newPassword: string,
    accessToken: string,
  ): Promise<AxiosResponse<any>> {
    const updatePasswordUri = API_CONFIG.ACCOUNT_SERVICE.UPDATE_PASSWORD_URI(
      accountUid,
    );

    const updatePasswordResponse = apiClient.put(
      updatePasswordUri,
      {
        accountUid: accountUid,
        oldPassword: oldPassword,
        newPassword: newPassword,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    );

    return updatePasswordResponse;
  }

  /**
   * Issues a request to activate a previously created user account
   * using an activation token that has been sent to the user out of band, meaning
   * on an alternative channel / factor like SMS or email.
   * @param customerUid - the users unique identifier 
   * @param activationToken - the activation token 
  
   */
  async activateAccount(
    customerUid: string,
    activationToken: string,
  ): Promise<AxiosResponse<any>> {
    const activateAccountUri = API_CONFIG.ACCOUNT_SERVICE.ACTIVATE_ACCOUNT_URI();

    const activateAccountResponse = apiClient.post(activateAccountUri, {
      customerUid: customerUid,
      activationToken: activationToken,
    });

    return activateAccountResponse;
  }
}
export default AccountService;
