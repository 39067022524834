import React from "react";
import {
  DateInput,
  Edit,
  BooleanInput,
  SimpleForm,
  TextInput,
} from "react-admin";

import ArticleEditAside from "../../02_molecules/ArticleEditAside";

export const ArticleEdit = (props) => {
  return (
    <Edit aside={<ArticleEditAside />} {...props}>
      <SimpleForm>
        <TextInput source="id" disabled />
        <TextInput source="uuid" disabled />
        <TextInput source="title" />
        <TextInput source="description" multiline={true} />
        <TextInput source="freelyAssignedAuthor" />
        <DateInput source="freelyAssignedDate" />
        <TextInput source="locationName" />
        <BooleanInput source="isDraft" />
        <BooleanInput source="isDeleted" disabled />
        <DateInput source="createdAt" disabled />
        <DateInput source="updatedAt" disabled />
      </SimpleForm>
    </Edit>
  );
};
