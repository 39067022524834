// in src/Menu.js
import { Collapse, useMediaQuery } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import BookRoundedIcon from "@material-ui/icons/BookRounded";
import DashboardRoundedIcon from "@material-ui/icons/DashboardRounded";
import HourglassFullRoundedIcon from "@material-ui/icons/HourglassFullRounded";
import LabelIcon from "@material-ui/icons/Label";
import LibraryBooksRoundedIcon from "@material-ui/icons/LibraryBooksRounded";
import StorageRoundedIcon from "@material-ui/icons/StorageRounded";
import TimelineRoundedIcon from "@material-ui/icons/TimelineRounded";
import UpdateRoundedIcon from "@material-ui/icons/UpdateRounded";
import DefaultIcon from "@material-ui/icons/ViewList";
import React from "react";
import { getResources, MenuItemLink } from "react-admin";
import { useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';

const Menu = ({ onMenuClick, logout }) => {
    const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));
    const open = useSelector((state:any) => state.admin.ui.sidebarOpen);
    const resources: any = useSelector(getResources);
  
    return (
    
   <div>
      <MenuItemLink
        to="/dashboard"
        primaryText="Dashboard"
        leftIcon={<DashboardRoundedIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <Divider style={{ marginTop: 10, marginBottom: 10 }} />
    
      {resources.map((resource) => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={
            (resource.options && resource.options.label) || resource.name
          }
          leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
    
      <MenuItemLink
        to="/analytics"
        primaryText="Analytics"
        leftIcon={<TimelineRoundedIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
    
      <Divider style={{ marginTop: 10, marginBottom: 10 }} />
    
      <MenuItemLink
        to="/jobs"
        primaryText="Cron Jobs"
        leftIcon={<UpdateRoundedIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
    
      <MenuItemLink
        to="/queues"
        primaryText="Queues"
        leftIcon={<HourglassFullRoundedIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <MenuItemLink
            to="/apiDocs"
            primaryText="API Docs"
            leftIcon={<LibraryBooksRoundedIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
    
          <MenuItemLink
            to="/misc"
            primaryText="Miscellaneous"
            leftIcon={<LabelIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        </List>
      </Collapse>
    
      <Divider style={{ marginTop: 10, marginBottom: 10 }} />
    
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <MenuItemLink
            to="/hosting"
            primaryText="Hosting"
            leftIcon={<StorageRoundedIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
    
          <MenuItemLink
            to="/blog"
            primaryText="Blog"
            leftIcon={<BookRoundedIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        </List>
      </Collapse>
      {isXSmall && logout}
    </div>
         
      
    );
}

export default withRouter(Menu);
















