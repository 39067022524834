import React from "react";
import {
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
  Filter,
  TextInput,
} from "react-admin";

const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by Name" source="name" alwaysOn />
    <TextInput label="Search by Uuid" source="uuid" />
  </Filter>
);

export const FamilyList = (props) => (
  <List {...props} filters={<SearchFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="uuid" />
      <TextField source="name" />
      <NumberField source="newsPaperReleaseCycleInWeeks" />
      <DateField source="newspaperReleaseCycleStartDate" />
      <DateField source="nextReleaseDate" showTime />

      <ReferenceArrayField
        label="members"
        reference="customers"
        source="memberIds"
      >
        <SingleFieldList>
          <ChipField source="displayName" />
        </SingleFieldList>
      </ReferenceArrayField>

      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <BooleanField source="isDeleted" />
    </Datagrid>
  </List>
);
