import React from "react";
import {
  Datagrid,
  List,
  NumberField,
  TextField,
  Filter,
  TextInput,
} from "react-admin";
import ConditionalProfileImageField from "../../01_atoms/ConditionalProfileImageField";

const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by Username" source="userName" alwaysOn />
    <TextInput label="Search by Uid" source="uid" />
  </Filter>
);

export const CustomerList = (props: any) => (
  <List {...props} filters={<SearchFilter />}>
    <Datagrid rowClick="edit">
      <NumberField source="id" />
      <TextField source="uid" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="displayName" />
     
      <ConditionalProfileImageField
        record={props.record}
      ></ConditionalProfileImageField>
    </Datagrid>
  </List>
);
