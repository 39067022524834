import React from "react";

const Iframe = (props) => {
  const { title, srcDoc, src } = props;

  return (
    // basic bootstrap classes. you can change with yours.
    <div
      style={{ position: "relative", overflow: "hidden", paddingTop: "56.25%" }}
    >
      <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          border: 0,
        }}
        title={title}
        src={src}
        srcDoc={srcDoc}
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
};

export default Iframe;
