
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ContactMailRoundedIcon from "@material-ui/icons/ContactMailRounded";
import CreateRoundedIcon from "@material-ui/icons/CreateRounded";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import FaceRoundedIcon from '@material-ui/icons/FaceRounded';
import FavoriteRoundedIcon from "@material-ui/icons/FavoriteRounded";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import PhotoLibraryRoundedIcon from "@material-ui/icons/PhotoLibraryRounded";
import crudProvider from 'ra-data-nestjsx-crud';
import React from "react";
import {
  Admin,
  EditGuesser,
  fetchUtils,
  Resource,
  ShowGuesser
} from "react-admin";
import authProvider from "./authProvider";
import { AccountRoles } from "./common/auth/authentication.roles";
import { API_CONFIG } from "./common/config/api.config";
import customRoutes from "./common/routes/routes";
import theme from "./components/00_constants/fz_theme";
import { AccountCreate } from "./components/03_organisms/Accounts/AccountCreate";
import { AccountEdit } from "./components/03_organisms/Accounts/AccountEdit";
import { AccountList } from "./components/03_organisms/Accounts/AccountList";
import { ArticleCreate } from "./components/03_organisms/Article/ArticleCreate";
import { ArticleEdit } from "./components/03_organisms/Article/ArticleEdit";
import { ArticleList } from "./components/03_organisms/Article/ArticleList";
import { ContactRequestList } from "./components/03_organisms/ContactRequests/ContactRequestList";
import { CustomerCreate } from "./components/03_organisms/Customer/CustomerCreate";
import { CustomerEdit } from "./components/03_organisms/Customer/CustomerEdit";
import { CustomerList } from "./components/03_organisms/Customer/CustomerList";
import { FamilyCreate } from "./components/03_organisms/Family/FamilyCreate";
import { FamilyEdit } from "./components/03_organisms/Family/FamilyEdit";
import { FamilyList } from "./components/03_organisms/Family/FamilyList";
import { ImageCreate } from "./components/03_organisms/Image/ImageCreate";
import { ImageEdit } from "./components/03_organisms/Image/ImageEdit";
import { ImageList } from "./components/03_organisms/Image/ImageList";
import { InvitationEdit } from "./components/03_organisms/Invitations/InvitationEdit";
import { InvitationList } from "./components/03_organisms/Invitations/InvitationList";
import FZAdminLayout from "./components/03_organisms/Layout/Layout";
import { NewspaperCreate } from "./components/03_organisms/Newspaper/NewspaperCreate";
import { NewspaperEdit } from "./components/03_organisms/Newspaper/NewspaperEdit";
import { NewspaperList } from "./components/03_organisms/Newspaper/NewspaperList";
import { PromotionCreate } from "./components/03_organisms/Promotions/PromotionCreate";
import { PromotionEdit } from "./components/03_organisms/Promotions/PromotionEdit";
import { PromotionList } from "./components/03_organisms/Promotions/PromotionList";
import { PromotionShow } from "./components/03_organisms/Promotions/PromotionShow";





export const httpClient = async (url, options:any = {}) => {

  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = await authProvider.getAccessToken();
  options.headers.set("Authorization", `Bearer ${token}`);

  return fetchUtils.fetchJson(url, options);
};



const dataProvider = crudProvider(API_CONFIG.ADMIN_BASE_URL, httpClient);


const App = () => (
  <Admin
    title={"FZ-Admin"}
    layout={FZAdminLayout}
    theme={theme}
    authProvider={authProvider}
    dataProvider={dataProvider}
    customRoutes={customRoutes}
  >
    {(permissions) => {
      const userIsAdmin = permissions.includes(AccountRoles.ADMINS);
      return [
        userIsAdmin && (
          <Resource
            name="families"
            options={{ label: "Families" }}
            icon={FavoriteRoundedIcon}
            list={FamilyList}
            create={FamilyCreate}
            edit={FamilyEdit}
          />
        ),

        userIsAdmin && (
          <Resource
            name="invitations"
            options={{ label: "Family Invitations" }}
            icon={EmailRoundedIcon}
            list={InvitationList}
            edit={InvitationEdit}
          />
        ),


        userIsAdmin && (
          <Resource
            name="accounts"
            options={{ label: "Accounts" }}
            icon={AccountCircleIcon}
            list={AccountList}
            show={ShowGuesser}
            create={AccountCreate}
            edit={AccountEdit}
          />
        ),

        userIsAdmin && (
          <Resource
            name="customers"
            options={{ label: "User Profiles" }}
            icon={FaceRoundedIcon}
            list={CustomerList}
            create={CustomerCreate}
            edit={CustomerEdit}
          />
        ),

        userIsAdmin && (
          <Resource
            name="newspapers"
            options={{ label: "Newspapers" }}
            icon={MenuBookRoundedIcon}
            list={NewspaperList}
            create={NewspaperCreate}
            edit={NewspaperEdit}
          />
        ),

        userIsAdmin && (
          <Resource
            name="articles"
            options={{ label: "Articles" }}
            icon={CreateRoundedIcon}
            list={ArticleList}
            create={ArticleCreate}
            edit={ArticleEdit}
          />
        ),
        userIsAdmin && (
          <Resource
            name="images"
            options={{ label: "Images" }}
            icon={PhotoLibraryRoundedIcon}
            list={ImageList}
            create={ImageCreate}
            edit={ImageEdit}
          />
        ),

        userIsAdmin && (
          <Resource
            name="contact-requests"
            icon={ContactMailRoundedIcon}
            options={{ label: "Contact Requests" }}
            list={ContactRequestList}
            edit={EditGuesser}
            show={ShowGuesser}
          />
        ),

        userIsAdmin && (
          <Resource
            name="promotions"
            icon={MonetizationOnRoundedIcon}
            options={{ label: "Promotions" }}
            list={PromotionList}
            edit={PromotionEdit}
            show={PromotionShow}
            create={PromotionCreate}
          />),
      ];
    }}
  </Admin>
);

export default App;
