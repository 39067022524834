import React from "react";
import {
  DateInput,
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export const FamilyCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <NumberInput source="newsPaperReleaseCycleInWeeks" />
      <DateInput source="newspaperReleaseCycleStartDate" />
      <DateInput source="nextReleaseDate" />
    </SimpleForm>
  </Create>
);
