import React from "react";
import { Datagrid, DateField, List, TextField } from "react-admin";

export const PromotionList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="uuid" />
      <TextField source="promotionCode" />
      <DateField source="activeUntil" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
