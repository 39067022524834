const colors = {
  primary: "#FFFFFF", // '#A9D9D9', // White
  accent: "#468C7F", //'#F2C53D', // Dark Green / Blue
  secondary: "#F2C53D", // Yellow
  tertiary: "#FF896E", // Coral / Red
  quartiary: "#8C6BEB", // Purple
  textStrong: "#000000DE",
  textMedium: "#00000099",
  textUnderline: "#000000",
  textImageoverlay: "#EFEFEF",

  backgroundExtraLight: "#FFFFFF",
  backgroundDark: "#BCBCBC",
  backgroundLight: "#EFEFEF",
  backgroundMedium: "#D9D9D9",
  backgroundAccent: "#F2D272",

  disabled: "#afafaf",
  active: "#FFFFFF",

  backdrop: "#468C7FAA",

  borderLight: "#DEDEDE",
  borderMedium: "#F2C43DDE",
  borderDark: "#00000099",

  success: {
    light: "#44b700",
    main: "#44b700",
    dark: "#44b700",
  },
  danger: {
    light: "#D81159",
    main: "#D81159",
    dark: "#D81159",
  },
  warning: {
    light: "#F2C43DDE",
    main: "#F2C43DDE",
    dark: "#F2C43DDE",
  },
};

export default colors;
