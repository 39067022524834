import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import colors from "./colors";




let theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.accent,
    },
    secondary: {
      main: colors.accent,
    },
    success: {
      light: colors.success.light,
      main: colors.success.main,
      dark: colors.success.dark,
    },
    warning: {
      light: colors.warning.light,
      main: colors.warning.main,
      dark: colors.warning.dark,
    },
    error: {
      light: colors.danger.light,
      main: colors.danger.main,
      dark: colors.danger.dark,
    },
    info: {
      light: colors.accent,
      main: colors.accent,
      dark: colors.accent,
    },
  },

  typography: {
    h1: {
      fontFamily: "BebasNeueBold",
      fontSize: "2.25rem",
      //letterSpacing: '-1.55px',
    },
    h2: { fontFamily: "BebasNeueRegular" },
    h3: { fontFamily: "BebasNeueRegular" },
    h4: { fontFamily: "BebasNeueRegular" },
    h5: { fontFamily: "BebasNeueRegular" },
    h6: { fontFamily: "BebasNeueBold", letterSpacing: "3.13" },
    fontFamily: [
      "OpenSansRegular",
      "Open Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

theme = responsiveFontSizes(theme);

export default theme;
