import React from "react";
import {
  DateInput,
  Create,
  BooleanInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export const ArticleCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="description" />
      <TextInput source="freelyAssignedAuthor" />
      <DateInput source="freelyAssignedDate" />
      <TextInput source="locationName" />
      <BooleanInput source="isDraft" />
    </SimpleForm>
  </Create>
);
