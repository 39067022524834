import { Divider, Typography } from "@material-ui/core";
import React, { ReactElement } from 'react';
import { BooleanInput, Edit, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { ForceAccountLogoutButton } from '../../02_molecules/ForceLogoutButton/ForceAccountLogoutButton';

export default function AccountEditAside(props: any): ReactElement {
    return (
    <div style={{ width: 200, margin: '1em', }}>
        <Typography variant="h6">Account-Actions</Typography>
        <Typography variant="body2">
            Administrative actions on an account.
        </Typography>
        <Divider style={{marginTop: 20, marginBottom: 20}}/>
        <Typography variant="body2" style={{marginTop: 20, marginBottom: 20}}>
            Forcefully logout this account. The user will not be able to re-login for 10 minutes.
        </Typography>
        <ForceAccountLogoutButton record={props.record}></ForceAccountLogoutButton>
    </div>
    )
}

export const AccountEdit = (props: any) => (
    <Edit  aside={<AccountEditAside />}  {...props}>
        <SimpleForm>
            <BooleanInput source="isDeleted" />
            <BooleanInput source="isLocked" />
            <BooleanInput source="isStaged" />
            <TextInput source="id" disabled/>
            <TextInput source="uid" disabled />
            <TextInput source="username" disabled />
            <TextInput source="roles" />
            <TextInput source="forcedLogoutAt" disabled />
            <ReferenceInput
                label="User Profile"
                source="customerProfile.id"
                reference="customers"
            >
                <SelectInput optionText="uid" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);