import React from "react";
import { DateInput, Edit, SimpleForm, TextInput, DateTimeInput } from "react-admin";

export const PromotionEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="uuid" />
      <TextInput source="promotionCode" />
      <DateTimeInput source="activeUntil" />
      <DateInput source="createdAt" />
      <DateInput source="updatedAt" />
    </SimpleForm>
  </Edit>
);
