import React from "react";
import { API_CONFIG } from "../../common/config/api.config";
import Link from "@material-ui/core/Link";

const ApiDocsPage = () => {
  return (
    <div>
      <h3>API</h3>
      <p>
        <span>
          We are working on embedding the API documentation directly into this
          app. In the meantime, please visit{" "}
        </span>
        <Link href={API_CONFIG.API_DOCUMENTATION_SERVICE.GET_API_DOCS_URL}>
          {API_CONFIG.API_DOCUMENTATION_SERVICE.GET_API_DOCS_URL}{" "}
        </Link>

        <span>to access the API documentation. </span>
      </p>
    </div>
  );
};

export default ApiDocsPage;
