import React from 'react'
import { List, Datagrid, BooleanField, TextField, ReferenceField } from 'react-admin';

export const AccountList = (props:any) => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <BooleanField source="isDeleted" />
            <BooleanField source="isLocked" />
            <BooleanField source="isStaged" />
            <TextField source="id" />
            <TextField source="uid" />
            <TextField source="username" />
            <TextField source="roles" />
            <TextField source="forcedLogoutAt" />

            <ReferenceField
        label="User Profile"
        source="customerProfile.id"
        reference="customers"
      >
        <TextField source="uid" />
      </ReferenceField>

      <ReferenceField
        label="Registered with promo code"
        source="joinedWithPromotionCode.id"
        reference="promotions"
      >
        <TextField source="promotionCode" />
      </ReferenceField>

        </Datagrid>
    </List>
);


