import React, { ReactElement } from "react";
import {
  DateInput,
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import {Typography, Divider} from "@material-ui/core";
import DownloadNewspaperButton from "../../02_molecules/DownloadNewspaperButton";
import GenerateNewspaperButton from "../../02_molecules/GenerateNewspaperButton";



export default function NewspaperEditAside(props: any): ReactElement {
  return (
  <div style={{ width: 200, margin: '1em', }}>
      <Typography variant="h6">Newspaper-Actions</Typography>
      <Typography variant="body2">
          Administrative actions on a family-newspaper
      </Typography>
      <Divider style={{marginTop: 20, marginBottom: 20}}/>
      <Typography variant="body2" style={{marginTop: 20, marginBottom: 20}}>
          Start the creation of a newspaper, downloading all articles and rendering them in a pdf.
      </Typography>
      <GenerateNewspaperButton record={props.record}></GenerateNewspaperButton>
      <Divider style={{marginTop: 20, marginBottom: 20}}/>
      <Typography variant="body2" style={{marginTop: 20, marginBottom: 20}}>
         Generate a download link for a previously generated newspaper.
      </Typography>
      <DownloadNewspaperButton record={props.record}></DownloadNewspaperButton>
  </div>
  )
}


export const NewspaperEdit = (props) => (
  <Edit aside={<NewspaperEditAside/>} {...props}>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="uuid" disabled />
      <BooleanInput source="isDeleted" disabled />
      <DateInput source="releaseCycleStartDate" />
      <DateInput source="releaseCycleEndDate" />
      <DateInput source="nextReleaseCycleEndDate" />
      <NumberInput source="editionNumber" />
      <NumberInput source="yearOfPublication" />
      <BooleanInput source="isCurrentEdition" />
      <BooleanInput source="isLastPublishedEdition" />
      <TextInput source="printableRepresentationUrl" />
      <ReferenceInput
        disabled
        label="Belongs To Family"
        source="belongsTo.id"
        reference="families"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <DateInput source="createdAt" disabled />
      <DateInput source="updatedAt" disabled />
    </SimpleForm>
  </Edit>
);
