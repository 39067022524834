import React from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  TextField,
  UrlField,
} from "react-admin";

export const ImageList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <BooleanField source="isDeleted" />
      <TextField source="id" />
      <TextField source="uuid" />
      <UrlField source="url" />
      <TextField source="key" />
      <TextField source="contentType" />
      <TextField source="originalName" />
      <TextField source="description" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <BooleanField source="isPartOfPersonalImageCollection" />
    </Datagrid>
  </List>
);
