import React from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  Filter,
  List,
  NumberField,
  ReferenceField,
  TextField,
  TextInput
} from "react-admin";

const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by Family Name" source="belongsTo.name" alwaysOn />
  </Filter>
);

export const NewspaperList = (props) => (
  <List {...props} filters={<SearchFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="uuid" />
      <DateField
        source="releaseCycleStartDate"
        showTime
        label="Release Cycle Start"
      />
      <DateField
        source="releaseCycleEndDate"
        showTime
        label="Release Cycle End"
      />
      <DateField
        source="nextReleaseCycleEndDate"
        showTime
        label="Next release Cycle End"
      />
      <NumberField source="editionNumber" />
      <NumberField source="yearOfPublication" />
      <BooleanField source="isCurrentEdition" />
      <BooleanField source="isLastPublishedEdition" />

      <ReferenceField
        label="Belongs To Family"
        source="belongsTo.id"
        reference="families"
      >
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="isDeleted" />

      <TextField source="printableRepresentationUrl" label="PDF location" />
      {/* <GenerateNewspaperButton />
      <DownloadNewspaperButton /> */}
     {/*  <FileField source="downloadLink" title="download"></FileField> */}
     {/*  <EditButton /> */}
    </Datagrid>
  </List>
);
