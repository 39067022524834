import React from "react";
import {
  DateInput,
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";

export const NewspaperCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <DateInput source="releaseCycleStartDate" />
      <DateInput source="releaseCycleEndDate" />
      <NumberInput source="editionNumber" />
      <BooleanInput source="isCurrentEdition" />
      <BooleanInput source="isLastPublishedEdition" />
      <TextInput source="printableRepresentationUrl" />
      <ReferenceInput
        label="Belongs To Family"
        source="belongsTo.id"
        reference="families"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
