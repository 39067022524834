import React from "react";
import { Edit, SimpleForm, TextInput, BooleanInput } from "react-admin";

export const CustomerEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="uid" disabled />
      <BooleanInput source="isOnline" disabled />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="displayName" />
    </SimpleForm>
  </Edit>
);
