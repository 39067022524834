import applicationConfig from "../config/application_config";
const baseUrl = applicationConfig.API_CONFIG.SERVICE_GATEWAY_URL;
const adminBaseUrl =
  applicationConfig.API_CONFIG.SERVICE_GATEWAY_URL + "/api/admin";

export const API_CONFIG = {
  ADMIN_BASE_URL: adminBaseUrl,
  BASE_URL: baseUrl,

  ARTICLE_SERVICE: {},

  USER_SERVICE: {},

  FAMILY_SERVICE: {
    ADMIN_ENDPOINTS: {
      ADMIN_BASE_URL: `${adminBaseUrl}/families`,
      GET_PDF_REPRESENTATION_BY_EDITION_NUMBER_URL: `${adminBaseUrl}/families/:familyUuid/newspapers/:editionNumber`,
      POST_NEW_RELEASE_CYCLE_FOR_FAMILY_URL: `${adminBaseUrl}/families/:familyUuid/releaseCycle`,
    },
  },

  NEWSPAPER_SERVICE: {
    USER_ENDPOINTS: {
      GET_NEWSPAPER_PDF_DOWNLOAD_LINK_URL: (
        familyUuid: string,
        newspaperUuid: string
      ) => {
        return `${baseUrl}/api/family/${familyUuid}/newspapers/${newspaperUuid}/downloadUrl`;
      },
    },
  },

  IMAGE_SERVICE: {},

  FEED_SERVICE: {},

  CRON_SERVICE: {
    GET_CRONJOBS_URL: `${adminBaseUrl}/cronjobs`,
  },

  QUEUE_SERVICE: {
    GET_QUEUE_UI_URL: `${adminBaseUrl}/queues/ui`,
  },

  API_DOCUMENTATION_SERVICE: {
    GET_API_DOCS_URL: `${baseUrl}/api/v1`,
  },

  EXTERNAL_LINKS: {
    API_HOST_URL: "https://digitalocean.com",
    BLOG_HOST_URL: "https://prismic.io",
  },
};
