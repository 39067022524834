import React, { useCallback, useState } from "react";
import { fetchEnd, fetchStart, useNotify } from "react-admin";
import { useDispatch } from "react-redux";
import { httpClient } from "../../App";
import { API_CONFIG } from "../../common/config/api.config";
import { Button } from "@material-ui/core";

/* const useStyles = makeStyles((theme) => {
  return {
    successLabel: {
      color: theme.palette.success.main, // 🔴
    },

    dangerLabel: {
      color: theme.palette.warning.main, // 🔴
    },
  };
}); */

const GenerateNewspaperButton = (props: any) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const { record } = props;

  const sendRequest = useCallback(() => {
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator

    const params = {
      fileLocation: "remote",
      representation: "pdf",
      generatorMode: "queued",
      yearOfPublication: record.yearOfPublication,
    };

    httpClient(
      `${API_CONFIG.FAMILY_SERVICE.ADMIN_ENDPOINTS.ADMIN_BASE_URL}/${
        record.belongsTo.uuid
      }/newspapers/${record.editionNumber}?${new URLSearchParams(params)}`,
      {
        method: "GET",
      }
    )
      .then((result) => {
        if (result.status === 200) {
          notify(
            "Newspaper-creation Job created. Check the 'Queues' tab for its progress."
          );
        }
      })
      .catch((e) => {
        notify("Could not generate Newspaper", "warning");
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
      });
  }, [dispatch, notify, record]);

  return (
    <Button
      color="secondary"
      variant="outlined"
      onClick={sendRequest}
      disabled={loading}
    >
      Generate PDF
    </Button>
  );
};

export default GenerateNewspaperButton;
