import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";

export const CustomerCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="uid" disabled />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="displayName" />
    </SimpleForm>
  </Create>
);
