import React from "react";
import { API_CONFIG } from "../../common/config/api.config";
import Link from "@material-ui/core/Link";

const QueueDashboardPage = () => {
  return (
    <div>
      <h3>Job-Queues</h3>
      <p>
        <span>
          We are working on embedding the queue-dashboard directly into this
          app. In the meantime, please visit{" "}
        </span>
        <Link href={API_CONFIG.QUEUE_SERVICE.GET_QUEUE_UI_URL}>
          {API_CONFIG.QUEUE_SERVICE.GET_QUEUE_UI_URL}{" "}
        </Link>

        <span>
          to monitor the status of active job-queues within the system.{" "}
        </span>
      </p>
    </div>
  );
};

export default QueueDashboardPage;
