import {
  StatusCodes
} from 'http-status-codes';
import { AccountRoles } from "./common/auth/authentication.roles";
import AccountService from "./common/services/AccountService";


// in src/authProvider.js
//const authenticationService = new AuthenticationService(authConfig);

const accountService = new AccountService();

export default {

  login: async ({ username, password }) => {
    try {
      const response = await accountService.login(username, password);
      if (response.status === StatusCodes.OK || response.status === StatusCodes.CREATED) {
        localStorage.setItem('token', response.data.accessToken);
        return Promise.resolve("/");
      } else {
        return Promise.reject();
      }
    } catch (error) {
      throw error;
    }
  },

  checkError: (error) => {
    if (error.response) {


      const status = error.response.status;
      if (status === StatusCodes.UNAUTHORIZED || status === StatusCodes.FORBIDDEN) {
        localStorage.removeItem('token');
        return Promise.reject();
      }
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },

  logout: async () => {
    let accessToken: string;
    try {
      accessToken = await accountService.getAccessToken();
    } catch (error) {
      return Promise.reject();
    }

    try {
      const response = await accountService.logout(accessToken);

      if (response.status === StatusCodes.OK || response.status === StatusCodes.CREATED) {
        localStorage.removeItem('token');
        return Promise.resolve();
      }
    } catch (error) {

      if (error.response && error.response.status === StatusCodes.UNAUTHORIZED) {
        localStorage.removeItem('token');
        return Promise.resolve();
      }
    }
  },


  checkAuth: async () => {

    try {
      const isAuthenticated = await accountService.isAuthenticated();
      if (isAuthenticated) {

        return Promise.resolve();
      }
      else {

        return Promise.reject();
      }
    } catch (error) {

      return Promise.reject();
    }

  },

  getPermissions: async () => {
    const token = await accountService.getAccessToken();
  
    if (token) {
      const decodedToken = await accountService.decodeToken(
        token
      );

      if (!decodedToken.roles.includes(AccountRoles.ADMINS)) {
        await accountService.logout(token);
        return Promise.reject();
      }

      if (decodedToken && decodedToken.roles) {
        return Promise.resolve(decodedToken.roles);
      } else {
        return Promise.reject();
      }
    }

    return Promise.reject();

  },

  getAccessToken: async () => {
    return await accountService.getAccessToken();
  },
};
