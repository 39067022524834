import React from "react";
import {  Show, SimpleShowLayout, TextField, DateField } from "react-admin";

export const PromotionShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="uuid" />
      <TextField source="promotionCode" />
      <TextField source="activeUntil" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);
