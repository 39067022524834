import React from 'react'
import { AutocompleteInput, BooleanInput, Create, ReferenceInput, SimpleForm, TextInput } from 'react-admin'

export const AccountCreate = (props: any) => {
    return (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="username" />
            <TextInput source="password" />
            <TextInput source="roles" /> 
            <TextInput source="forcedLogoutAt" />
            <BooleanInput source="isDeleted" />
            <BooleanInput source="isLocked" />
            <BooleanInput source="isStaged" />
            <ReferenceInput
                    label="User Profile"
                    source="customerProfile.id"
                    reference="customers"
                >
                <AutocompleteInput optionText="uid" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
    )
}


