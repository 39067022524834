import React from "react";
import { BooleanInput, Create, SimpleForm, TextInput } from "react-admin";

export const ImageCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="url" />
      <TextInput source="key" />
      <TextInput source="contentType" />
      <TextInput source="originalName" />
      <TextInput source="description" />
      <BooleanInput source="isPartOfPersonalImageCollection" />
    </SimpleForm>
  </Create>
);
