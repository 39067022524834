/**
 * A set of utilities used to recurring api operations
 */
class ApiUtilities {
  static handleApiError(error: any) {
    if (error.response) {
      return error.response;
      // client received an error response (5xx, 4xx)
    } else if (error.request) {
      // client never received a response, or request never left

      return error;
    } else {
      return error;
    }
  }
}

export default ApiUtilities;
