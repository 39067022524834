import { ApplicationConfig } from "./types";

const developmentEnvConfig = {
  API_CONFIG: {
    SERVICE_GATEWAY_URL: process.env.REACT_APP_DEVELOPMENT_SERVICE_GATEWAY_URL,
  },

  AUTH_CONFIG: {
    OKTA_CLIENT_ID: process.env.REACT_APP_DEVELOPMENT_OKTA_CLIENT_ID,
    OKTA_ISSUER: process.env.REACT_APP_DEVELOPMENT_OKTA_ISSUER,
    OKTA_REDIRECT_URI: process.env.REACT_APP_DEVELOPMENT_OKTA_REDIRECT_URI,
  },
};

const stagingEnvConfig = {
  API_CONFIG: {
    SERVICE_GATEWAY_URL: process.env.REACT_APP_STAGING_SERVICE_GATEWAY_URL,
  },
  AUTH_CONFIG: {
    OKTA_CLIENT_ID: process.env.REACT_APP_STAGING_OKTA_CLIENT_ID,
    OKTA_ISSUER: process.env.REACT_APP_STAGING_OKTA_ISSUER,
    OKTA_REDIRECT_URI: process.env.REACT_APP_STAGING_OKTA_REDIRECT_URI,
  },
};

const productionEnvConfig = {
  API_CONFIG: {
    SERVICE_GATEWAY_URL: process.env.REACT_APP_PRODUCTION_SERVICE_GATEWAY_URL,
  },
  AUTH_CONFIG: {
    OKTA_CLIENT_ID: process.env.REACT_APP_PRODUCTION_OKTA_CLIENT_ID,
    OKTA_ISSUER: process.env.REACT_APP_PRODUCTION_OKTA_ISSUER,
    OKTA_REDIRECT_URI: process.env.REACT_APP_PRODUCTION_OKTA_REDIRECT_URI,
  },
};

let config;
switch (process.env.REACT_APP_STAGE) {
  case "development":
    console.log("Activating Development Environment");
    config = developmentEnvConfig;
    break;
  case "staging":
    console.log("Activating Staging Environment");
    config = stagingEnvConfig;
    break;
  case "production":
    config = productionEnvConfig;
    break;

  default:
    throw new Error(
      "No Envrionment is set. Please set a value for the REACT_APP_STAGE variable."
    );
}

const applicationConfig: ApplicationConfig = {
  // Add common config values here
  ...config,
};

export default applicationConfig;
