import React from "react";
import {
  BooleanInput,
  DateInput,
  Edit,
  SimpleForm,
  TextInput,
} from "react-admin";

export const ImageEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="uuid" disabled />

      <TextInput source="url" />
      <TextInput source="key" />
      <TextInput source="contentType" />
      <TextInput source="originalName" />
      <TextInput source="description" />
      <BooleanInput source="isPartOfPersonalImageCollection" />

      <BooleanInput source="isDeleted" disabled />
      <DateInput source="createdAt" disabled />
      <DateInput source="updatedAt" disabled />
    </SimpleForm>
  </Edit>
);
