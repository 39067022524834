import applicationConfig from '../config/application_config';
const baseUrl = applicationConfig.API_CONFIG.SERVICE_GATEWAY_URL;

export const API_CONFIG = {


  AUTHENTICATION_SERVICE: {
    ADMIN_ENDPOINTS: {
      FORCE_LOGOUT_ACCOUNT_URL: (accountUid: string) => `${baseUrl}/api/admin/accounts/${accountUid}/logout`,
      LOCK_ACCOUNT_URL: () => `${baseUrl}/api/admin/accounts/lock`,
    },
  },


  ACCOUNT_SERVICE: {
    LOGIN_ACCOUNT_URI: () => {
      return `${baseUrl}/api/authentication/login`;
    },

    LOGOUT_ACCOUNT_URI: () => {
      return `${baseUrl}/api/authentication/logout`;
    },

    

    POST_NEW_ACCOUNT_URI: () => {
      return `${baseUrl}/api/authentication/register`;
    },

    ACTIVATE_ACCOUNT_URI: () => {
      return `${baseUrl}/api/authentication/activate`;
    },

    UPDATE_PRIMARY_EMAIL_URI: () => {
      return `${baseUrl}/api/authentication/update-email`;
    },

    UPDATE_PASSWORD_URI: (customerUid: string) => {
      return `${baseUrl}/api/authentication/${customerUid}/password`;
    },

    FORGOT_PASSWORD_URI: () => {
      return `${baseUrl}/api/authentication/forgot-password`;
    },

    RESET_PASSWORD_URI: () => {
      return `${baseUrl}/api/authentication/reset-password`;
    },
  },

  ARTICLE_SERVICE: {
    GET_ARTICLES_URI: () => {
      return `${baseUrl}/api/articles`;
    },
    GET_ARTICLES_FOR_USER_URI: (userUuid: string) => {
      return `${baseUrl}/api/customer/${userUuid}/articles`;
    },
    GET_ARTICLE_URI: (articleUuid: string) => {
      return `${baseUrl}/api/articles/${articleUuid}`;
    },
    POST_ARTICLE_URI: (familyUuid: string) => {
      return `${baseUrl}/api/family/${familyUuid}/currentNewspaper/articles`;
    },
    POST_ARTICLE_IMAGE_URI: (articleUuid: string) => {
      return `${baseUrl}/api/article/${articleUuid}/images`;
    },
    POST_PREUPLOADED_IMAGE_TO_ARTICLE_URI: (articleUuid: string) => {
      return `${baseUrl}/api/article/${articleUuid}/preuploadedImages`;
    },
    PUT_ARTICLE_URI: (familyUuid: string, articleUuid: string) => {
      return `${baseUrl}/api/family/${familyUuid}/currentNewspaper/article/${articleUuid}`;
    },
    PATCH_ARTICLE_URI: (articleUuid: string) => {
      return `${baseUrl}/api/articles/${articleUuid}`;
    },
    DELETE_ARTICLE_URI: (articleUuid: string) => {
      return `${baseUrl}/api/article/${articleUuid}`;
    },
    DELETE_IMAGE_FROM_ARTICLE_URI: (articleUuid: string, imageUuid: string) => {
      return `${baseUrl}/api/article/${articleUuid}/image/${imageUuid}`;
    },
    GET_LAST_NEWSPAPER_FOR_FAMILY_URL: (familyUuid: string) => {
      return `${baseUrl}/api/family/${familyUuid}/latestPublishedNewspaper`;
    },
  },

  USER_SERVICE: {
    GET_OWN_PROFILE_URI: (userUuid: string) => {
      return `${baseUrl}/api/customer/${userUuid}/profile`;
    },

    UPDATE_OWN_PROFILE_URI: (userUuid: string) => {
      return `${baseUrl}/api/customer/${userUuid}/profile`;
    },

    INTRODUCE_SELF_URI: () => {
      return `${baseUrl}/api/customers/introduce`;
    },

    PUT_PROFILE_IMAGE_URI: (userUuid: string) => {
      return `${baseUrl}/api/customer/${userUuid}/profileImage`;
    },

    PUT_USER_SETTINGS_URI: (userUuid: string) => {
      return `${baseUrl}/api/customer/${userUuid}/settings`;
    },
  },

  FAMILY_SERVICE: {
    GET_FAMILY_URL: (familyUuid: string) => {
      return `${baseUrl}/api/family/${familyUuid}`;
    },

    GET_FAMILIES_FOR_USER_URI: (customerUid: string) => {
      return `${baseUrl}/api/customer/${customerUid}/families`;
    },

    CREATE_FAMILY_URI: () => {
      return `${baseUrl}/api/families`;
    },

    EDIT_FAMILY_URI: (familyUuid: string) => {
      return `${baseUrl}/api/family/${familyUuid}`;
    },

    CREATE_INVITE_TO_FAMILY_URI: (familyUuid: string) => {
      return `${baseUrl}/api/family/${familyUuid}/invitations`;
    },

    DELETE_INVITE_TO_FAMILY_URI: (
      familyUuid: string,
      invitationUuid: string,
    ) => {
      return `${baseUrl}/api/family/${familyUuid}/invitations/${invitationUuid}`;
    },

    JOIN_FAMILY_URI: () => {
      return `${baseUrl}/api/families/invitations`;
    },

    LEAVE_FAMILY_URI: (familyToLeaveUuid: string) => {
      return `${baseUrl}/api/family/${familyToLeaveUuid}/myMembership`;
    },

    END_OTHERS_MEMBERSHIP_IN_FAMILY_URI: (
      familyUuid: string,
      customerUid: string,
    ) => {
      return `${baseUrl}/api/family/${familyUuid}/members/${customerUid}`;
    },
  },

  NEWSPAPER_SERVICE: {
    GET_CURRENT_NEWSPAPER_FOR_FAMILY: (familyUuid: string) => {
      return `${baseUrl}/api/family/${familyUuid}/currentNewspaper`;
    },
    GET_USER_CONTRIBUTIONS_IN_CURRENT_NEWSPAPER_OF_FAMILY_URL: (
      familyUuid: string,
    ) => {
      return `${baseUrl}/api/family/${familyUuid}/currentNewspaper/myContributions`;
    },
  },

  IMAGE_SERVICE: {
    GET_PRESIGNED_URL_FOR_PROTECTED_NEWSPAPER_ARTICLE_IMAGE: (
      familyUuidOfFamilyThatOwnsNewspaper: string,
      newspaperUuidOfNewspaperInWhichArticleIsPublished: string,
      articleUuidOfArticleToWhichTheImageBelongs: string,
      imageUuidOfImageThatShallBeAccessed: string,
    ) => {
      return `${baseUrl}/api/family/${familyUuidOfFamilyThatOwnsNewspaper}/newspapers/${newspaperUuidOfNewspaperInWhichArticleIsPublished}/articles/${articleUuidOfArticleToWhichTheImageBelongs}/images/${imageUuidOfImageThatShallBeAccessed}/presignedImageUrl`;
    },

    GET_PRESIGNED_URL_FOR_PROTECTED_IMAGE_URI: (imageUuid: string) => {
      return `${baseUrl}/api/images/${imageUuid}/presignedImageUrl`;
    },
    GET_PRESIGNED_UPLOAD_FILE_UPLOAD_URL: () => {
      return `${baseUrl}/api/images/presignedUploadUrl`;
    },

    GET_PRESIGNED_URL_FOR_PROTECTED_FAMILY_RESOURCE_URL: (
      familyUuid: string,
      imageUuid: string,
    ) => {
      return `${baseUrl}/api/family/${familyUuid}/images/${imageUuid}`;
    },
  },

  FEED_SERVICE: {
    GET_PERSONALIZED_FEED_URL: (customerUuid: string) => {
      return `${baseUrl}/api/customer/${customerUuid}/feed`;
    },
  },

  CONTACT_SERVICE: {
    POST_NEW_CONTACT_REQUEST_URI: () => {
      return `${baseUrl}/api/contact-requests`;
    },
  },
};
