import React from 'react'
import { Create, DateTimeInput, SimpleForm, TextInput } from 'react-admin'

export const PromotionCreate = (props: any) => {
    return (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="promotionCode" />
            <DateTimeInput source="activeUntil" />
        </SimpleForm>
    </Create>
    )
}


