import React, { ReactElement, useState, useCallback } from 'react'
import { fetchStart, useNotify, fetchEnd } from 'react-admin';
import { httpClient } from '../../../App';
import { API_CONFIG } from '../../../common/api';
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";

export interface Props {
  record: any
}

export const ForceAccountLogoutButton = (props: Props): ReactElement => {
    
    const dispatch = useDispatch();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const { record } = props;
  
    const sendRequest = useCallback(() => {
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator

     httpClient(
      API_CONFIG.AUTHENTICATION_SERVICE.ADMIN_ENDPOINTS.FORCE_LOGOUT_ACCOUNT_URL(record.uid),
      {
        method: "POST",
      }
    )
      .then((result) => {
        if (result.status === 200) {
          notify(
            "User forcefully logged out."
          );
        }
      })
      .catch((e) => {
        notify("Could not forcefully logout this account", "warning");
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
      });
  }, [dispatch, notify, record]);
     
    
    
    return (
        <div>
              <Button
          color="secondary"
          onClick={sendRequest}
          disabled={loading }
          variant={"outlined"}
          
        >
         Force Logout
        </Button>
        </div>
    );
}


