// in src/comments/ApproveButton.js
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { fetchEnd, fetchStart, useNotify } from "react-admin";
import { useDispatch } from "react-redux";
import { httpClient } from "../../../App";
import { API_CONFIG } from "../../../common/config/api.config";

const useStyles = makeStyles((theme) => {
  return {
    successLabel: {
      color: theme.palette.success.main, // 🔴
    },

    dangerLabel: {
      color: theme.palette.warning.main, // 🔴
    },
  };
});

const CronJobList = (props) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ cronjobs: [] });

  useEffect(() => {
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator

    httpClient(API_CONFIG.CRON_SERVICE.GET_CRONJOBS_URL, { method: "GET" })
      .then((result) => {
        const data = JSON.parse(result.body);

        setData(data);
      })
      .catch((e) => {
        notify("Could not fetch cronjobs", "warning");
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
      });
  }, [dispatch, notify, props]);

  const classes = useStyles();

  return (
    <div>
      {loading && "Fetching Cronjobs.."}
      <Typography variant="h6"> Cron Jobs </Typography>
      {data?.cronjobs?.map((cronjob, index) => {
        return (
          <Card
            key={index}
            style={{
              maxWidth: "50%",
            }}
          >
            <CardContent>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  <Typography variant="subtitle1"> Cron Job ID: </Typography>
                  {cronjob.name}
                </span>
                <span>
                  {cronjob.isRunning ? (
                    <span className={classes.successLabel}>Running</span>
                  ) : (
                    <span className={classes.dangerLabel}>Not running</span>
                  )}
                </span>
              </div>

              <span>
                <Typography variant="subtitle1">Next Execution at:</Typography>
                {cronjob.nextExecutionTime}
              </span>
              <span>
                <Typography variant="subtitle1">
                  Last execution time:{" "}
                </Typography>
                {cronjob.lastExecutionTime}
              </span>
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
};

export default CronJobList;
