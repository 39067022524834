import React, { ReactElement } from "react";

import { Typography, Card } from "@material-ui/core";

export default function ArticleEditAside(props: any): ReactElement {
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <Card>
        <Typography variant="h5" style={{ textAlign: "center" }}>
          Details
        </Typography>
        {props.record?.photos?.length > 0 ? (
          <>
            <Typography variant="body1">
              {`ImageId: ${props.record.photos[0].id}`}
            </Typography>
            <Typography variant="body1">
              {`ImageUuid: ${props.record.photos[0].uuid}`}
            </Typography>
          </>
        ) : (
          <Typography variant="body1">
            {`ImageId: No image attached `}
          </Typography>
        )}
      </Card>
    </div>
  );
}
